@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

body,
#root {
  min-height: 100vh;
  background-color: #fff;
}
.progress {
  background-color: #ffffff8a;
}
html {
  height: -webkit-fill-available;
}
.sidebar-nav {
  max-width: 210px;
}
.sidebar-nav .nav-item a.nav-link {
  padding-left: 0;
}
.content-shadow {
  overflow-y: auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}
#opinion-list {
  .analyze-sentiment-btn {
    visibility: hidden;
  }
  .col:hover .analyze-sentiment-btn {
    visibility: visible;
  }
}
body {
  a {
    color: #032c3e;
    // text-decoration: none;
  }
}
nav {
  z-index: 101;
}
ul.steps {
  display: flex;
  margin-bottom: 0;
  list-style-type: none;
  padding-top: 5px;
  li {
    a {
      // display: inline-block;
      // position: relative;
      // width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 18px 20px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px 4px 0 0;
      box-sizing: border-box;
      color: #fff;
      text-decoration: none;
      &:hover {
        background-color: #344d5b;
      }
      &.active {
        color: #042d3f;
        background-color: #fff;
        .num {
          background-color: #fff;
          color: #000;
          border-color: #000;
        }
      }
      .num {
        display: inline-block;
        width: 25px;
        height: 25px;
        color: #fff;
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 50%;
        text-align: center;
        line-height: 15px;
        font-size: 15px;
      }

      // .num {
      //   border-radius: 100px;
      //   background-color: #f8f9fa5b;
      //   color: #000;
      //   display: inline-block;
      //   width: 25px;
      //   // height: 25px;
      //   text-align: center;
      // }
    }
  }
}
.opinionsContainer {
  span.highlight {
    background-color: rgba(255, 255, 0, 0.552);
  }
  h2 {
    margin-bottom: 15px;
  }
  .opinion {
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.624);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  align-items: center;
  justify-content: center;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #9c27b0;
      margin: -4px 0 0 -4px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
    }
    &:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
    }
    &:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
    }
    &:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
    }
    &:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }
    &:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
    }
    &:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
    }
    &:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
    }
    &:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-background {
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  background: linear-gradient(-45deg, #eeeeee, #dddddd, #cccccc, #bbbbbb);
  background-size: 400% 400%;
  animation: loadingAnimation 8s ease infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

main {
  // height: 100vh;
  /* height: -webkit-fill-available; */
  // max-height: 100vh;
  // overflow-x: auto;
  // overflow-y: hidden;
}

.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
  &:hover,
  &:focus {
    color: rgba(var(--bs-emphasis-color-rgb), 0.85);
    background-color: var(--bs-tertiary-bg);
  }
  &::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
  }
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav {
  a:hover,
  a:focus {
    background-color: var(--bs-tertiary-bg);
  }
}

.scrollarea {
  overflow-y: auto;
}
.results-container {
  // display: flex;
  //   gap: 15px;

  .summary-container {
    // padding: 0 15px;
    // max-width: 50%;
    .summary-list {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .summary-list-row {
        display: flex;
        justify-content: space-between;
        .summary-list-row-title {
          font-weight: 600;
        }
      }
    }
  }

  .sentiment-analysis-preview-container {
    .items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;
      border-right: 1px solid #eee;
      .badge {
        white-space: normal;
        text-align: left;
      }
      .spinner-border {
        position: absolute;
        right: 15px;
      }
      .btn-analyse {
        position: absolute;
        right: 15px;
        display: none;
      }
      .sentiment-analysis-preview {
        border-top: 1px solid #eee;
        position: relative;
        overflow: hidden;
        width: 300px;
        box-sizing: border-box;
        gap: 10px;
        background: rgb(255 255 255 / 52%);
        &.transparent {
          background-color: transparent;
          border: none;
        }
        &:hover {
          .btn-analyse {
            display: flex;
            flex-direction: row-reverse;
            gap: 5px;
          }
        }
      }
    }
  }
}
